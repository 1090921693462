export const projectStatuses = {
  incomplete: 'incomplete',
  active: 'active',
  upcoming: 'upcoming',
  completed: 'completed',
};

export const saleStatuses = {
  soldOut: 'soldOut',
};
